import React, { useState , useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios"; // Import axios for API calls

const Resize = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageSettings, setImageSettings] = useState([]);
    const [uploading, setUploading] = useState(false);
    const count = localStorage.getItem('maxfilecount');
    const [maxFilesAllowed, setMaxFilesAllowed] = useState(10); // Default to 10 for guests
    // Handle file drop
    // Check if user is logged in (you can customize this with your JWT handling logic)

     const myUrl = "https://greatlobbyist.com/imageConverter/api/resize"
    //const myUrl = 'http://localhost:5000/resize'


    const checkUserSession = () => {
        const token = localStorage.getItem('token'); // Assuming the token is stored here
        const sessionStartTime = localStorage.getItem('sessionStartTime');
        const currentTime = Date.now();
        const count = localStorage.getItem('maxfilecount');
        console.log(count)
        // If token exists and session is within 24 hours
        if (token && sessionStartTime && currentTime - sessionStartTime < 86400000) {
            // Logged-in user: Max files 15 per session
            setMaxFilesAllowed(count);
        } else {
            // Guest: Max files 10 per session
            setMaxFilesAllowed(count);
        }
    };

        // Update the session start time when user logs in
        const handleLogin = (token) => {
            localStorage.setItem('authToken', token);
            localStorage.setItem('sessionStartTime', Date.now().toString());
            checkUserSession();
        };
    
        useEffect(() => {
            checkUserSession();
        }, []);
    

        // Handle file drop
        const onDrop = (acceptedFiles) => {
            if (selectedFiles.length + acceptedFiles.length <= maxFilesAllowed) {
                setSelectedFiles([...selectedFiles, ...acceptedFiles]);
                const metaData = acceptedFiles.map((file) => {
                    return new Promise((resolve) => {
                        const img = new Image();
                        img.onload = () => {
                            resolve({
                                name: file.name,
                                size: (file.size / 1024).toFixed(2), // KB
                                type: file.type,
                                width: img.width,
                                height: img.height,
                                progress: 0, // Progress bar for upload
                                newWidth: img.width, // Set initial new width
                                newHeight: img.height, // Set initial new height
                            });
                        };
                        img.src = URL.createObjectURL(file);
                    });
                });
        
                // Resolve promises and set image settings
                Promise.all(metaData).then((data) => {
                    setImageSettings(data);
                });
            } else {
                alert(`You can only upload a maximum of ${maxFilesAllowed} files.`);
            }
        };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.png,.jpg,.jpeg,.webp',
        multiple: true,
        maxFiles: count,
    });

    // Trigger resize and API call to resize the image
    const handleResize = async (file, newWidth, newHeight) => {
        setUploading(true);

        try {
            console.log(
                "Resizing to width:",
                newWidth,
                "height:",
                newHeight
            );

            // Call the resize API
            const resizedImage = await resizeImageAPI(file, newWidth, newHeight);
            console.log("Resized image:", resizedImage);

            // Trigger download of the resized image
            downloadImage(resizedImage, file.name);
        } catch (error) {
            console.error("Error resizing image:", error);
        } finally {
            setUploading(false); // Set uploading state back to false
        }
    };

    // Function to call the resize API (sending request to the Node backend)
    const resizeImageAPI = (file, width, height) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("width", width);
        formData.append("height", height);

        return axios
            .post(myUrl, formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType: "blob", // expecting binary data (image)
            })
            .then((response) => {
                // Create a URL for the resized image
                const resizedImageURL = URL.createObjectURL(response.data);
                return resizedImageURL;
            });
    };

    // Function to trigger the download of the resized image
    const downloadImage = (imageURL, fileName) => {
        const a = document.createElement("a");
        a.href = imageURL;
        a.download = `resized-${fileName}`; // Optionally prepend 'resized-' to the filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up the DOM after the download

        const count = localStorage.getItem('maxfilecount');
        localStorage.setItem('maxfilecount', count-1);
    };

    // Handle changes in the width and height inputs
    const handleDimensionChange = (e, index, dimension) => {
        const value = parseInt(e.target.value, 10); // Get the input value as a number
        const updatedSettings = [...imageSettings];
    
        // Ensure the object exists at the index
        if (!updatedSettings[index]) {
            updatedSettings[index] = {
                width: 1, // Default values in case it's undefined
                height: 1,
                newWidth: 1,
                newHeight: 1,
            };
        }
    
        const originalWidth = updatedSettings[index]?.width || 1; // Avoid division by zero
        const originalHeight = updatedSettings[index]?.height || 1;
    
        if (dimension === "newWidth" && value) {
            // Calculate the new height based on the aspect ratio
            updatedSettings[index].newWidth = value;
            updatedSettings[index].newHeight = Math.round(
                (originalHeight / originalWidth) * value
            );
        } else if (dimension === "newHeight" && value) {
            // Calculate the new width based on the aspect ratio
            updatedSettings[index].newHeight = value;
            updatedSettings[index].newWidth = Math.round(
                (originalWidth / originalHeight) * value
            );
        }
    
        setImageSettings(updatedSettings); // Update state with the modified settings
    };

    return (
        <div className="image-converter-container">
            <h1>Resize IMAGE</h1>
            <h3>
                Resize  JPG , WEBP or PNG images by specifying custom height
                and width in pixels. Modify image dimensions in bulk.
            </h3>

            <div>
                {!uploading && (
                    <div className="upload-area" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <h2>Choose your file</h2>
                        <p>
                            Drag and drop your images or click to select files.
                        </p>
                    </div>
                )}
            </div>

            <div className="image-settings-panel">
                {selectedFiles.length > 0 && !uploading ? (
                    <h3>Selected Images</h3>
                ) : null}

                {selectedFiles.map((file, index) => {
                    const imgSrc = URL.createObjectURL(file);
                    const settings = imageSettings[index] || {};

                    return (
                        <div key={index} className="thumbnail-container">
                            <img
                                src={imgSrc}
                                alt={`Preview ${index + 1}`}
                                className="thumbnail"
                                style={{ maxHeight: "100px" }}
                            />
                            <div className="image-meta">
                                <label>Current Dimensions</label>
                                <p>
                                    <label>Width:</label> {settings.width}px{" "}
                                    <label>Height:</label> {settings.height}px
                                </p>
                            </div>
                            <div className="resize-options">
                                <label>Resize Width</label>
                                <input
                                    type="number"
                                    placeholder="Enter width"
                                    value={
                                        settings.newWidth || settings.width
                                    } // Ensure controlled input
                                    onChange={(e) =>
                                        handleDimensionChange(
                                            e,
                                            index,
                                            "newWidth"
                                        )
                                    }
                                />

                                <label>Resize Height</label>
                                <input
                                    type="number"
                                    placeholder="Enter height"
                                    value={
                                        settings.newHeight || settings.height
                                    } // Ensure controlled input
                                    onChange={(e) =>
                                        handleDimensionChange(
                                            e,
                                            index,
                                            "newHeight"
                                        )
                                    }
                                />

                                <button
                                    onClick={() =>
                                        handleResize(
                                            file,
                                            settings.newWidth,
                                            settings.newHeight
                                        )
                                    }
                                    className="btn btn-light border border-primary rounded-pill text-primary py-2 px-4 me-4"
                                    style={{ marginTop: "10px" }}
                                >
                                    Resize
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Resize;
