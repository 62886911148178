import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  //const [maxfile, maxfile] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
       // localStorage.setItem('maxfilecount', 20);
        setUser(decoded);
      } catch {
        localStorage.removeItem('token');
     //   localStorage.setItem('maxfilecount', 10);
      }
    }
  }, []);

  const login = (token) => {
   const mytoken =  localStorage.setItem('token', token);
    const sessionStartTime = localStorage.getItem('sessionStartTime');
    const sessionExpiryTime = localStorage.getItem('sessionExpiryTime');
    const currentTime = new Date().getTime();
    if (!sessionStartTime) {
      const newSessionStartTime = currentTime;
      const newSessionExpiryTime = newSessionStartTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      localStorage.setItem('sessionStartTime', newSessionStartTime);
      localStorage.setItem('sessionExpiryTime', newSessionExpiryTime);
      if(mytoken){
      //  localStorage.setItem('maxfilecount', 20);
      }else{
      //  localStorage.setItem('maxfilecount', 10);
      }
     
    }
    const decoded = jwtDecode(token);
    setUser(decoded);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('sessionStartTime');
    localStorage.removeItem('sessionExpiryTime');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
