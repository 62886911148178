import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const OurService = () => {
  useEffect(() => {
    // Animating the Introductory Section
    gsap.fromTo(
      ".container_new .text-center",
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".container_new .text-center",
          start: "top 90%", // Animation starts when top of the section is 90% into the viewport
          end: "top 10%", // Animation ends before scrolling past the center
          scrub: false,
        },
      }
    );

    // Animating each Service Section
    gsap.utils.toArray(".service-item").forEach((serviceItem) => {
      gsap.fromTo(
        serviceItem,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power1.out",
          scrollTrigger: {
            trigger: serviceItem,
            start: "top 90%", // Animation starts when top of the service item is 90% into the viewport
            end: "top 20%", // Animation ends before scrolling past the center
            scrub: false, // Ensures the animation starts and completes as expected
          },
        }
      );
    });
  }, []);

  return (
    <div className="container-fluid">
      <div className="container_new">
        {/* Introductory Section */}
        <div
          className="text-center mx-auto mb-5"
          style={{ maxWidth: '900px' }}
        >
          {/* <h4 className="mb-1 text-primary">Our Service</h4> */}
          <h1 className="display-5 mb-4">What We Can Do For You</h1>
          <p className="mb-0">
            Dolor sit amet consectetur, adipisicing elit. Ipsam, beatae maxime.
          </p>
        </div>
      </div>

      {/* Services Section */}
      <div className="row justify-content-center container_new">
        {[
          {
            title: "Email Newsletters",
            icon: "fas fa-mail-bulk",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          },
          {
            title: "Acquisition Emails",
            icon: "fas fa-thumbs-up",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          },
          {
            title: "Retention Emails",
            icon: "fa fa-subway",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          },
          {
            title: "Promotional Emails",
            icon: "fas fa-sitemap",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          },
        ].map((service, index) => (
          <div className="col-md-6 col-lg-4 col-xl-3 mb-4" key={index}>
            <div className="service-item text-center rounded p-4">
              <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                <i className={`${service.icon} fa-5x text-secondary`}></i>
              </div>
              <h4 className="mb-4">{service.title}</h4>
              <p className="mb-4">{service.description}</p>
              <a href="#" className="btn btn-light rounded-pill text-primary py-2 px-4">
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurService;
