import React, { useState , useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './ConvertFromJPG.css';
import 'font-awesome/css/font-awesome.min.css';

const ConvertFromJPG = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imageSettings, setImageSettings] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [compressionData, setCompressionData] = useState({});
  const [uploading, setUploading] = useState(false);
//  localStorage.setItem('maxfilecount', 10);
  const count = localStorage.getItem('maxfilecount');
  const [maxFilesAllowed, setMaxFilesAllowed] = useState(count); 


    // Check if user is logged in (you can customize this with your JWT handling logic)
    const checkUserSession = () => {
      const token = localStorage.getItem('token'); // Assuming the token is stored here
      const sessionStartTime = localStorage.getItem('sessionStartTime');
      const currentTime = Date.now();
      const count = localStorage.getItem('maxfilecount');
      console.log(count)
      // If token exists and session is within 24 hours
      if (token && sessionStartTime && currentTime - sessionStartTime < 86400000) {
          // Logged-in user: Max files 15 per session
          setMaxFilesAllowed(count);
      } else {
          // Guest: Max files 10 per session
          setMaxFilesAllowed(count);
      }
  };

  
    const myUrl = 'https://greatlobbyist.com/imageConverter/api/convert-from-jpg'
   // const myUrl = 'http://localhost:5000/convert-from-jpg'

    // Update the session start time when user logs in
    const handleLogin = (token) => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('sessionStartTime', Date.now().toString());
        checkUserSession();
    };

    useEffect(() => {
        checkUserSession();
    }, []);

    // Handle file drop
    const onDrop = (acceptedFiles) => {

      console.log(count)
      // Filter out any files that are already selected
      const newFiles = acceptedFiles.filter((file) => {
          return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
      });
  
      // If there are no new files to add, show an alert
      if (newFiles.length === 0) {
          alert('This file has already been selected or format is not acceptable');
          return;
      }

      if(count <= 0){
          alert('free limit has been exeeded');
          return;
      }
        if (selectedFiles.length + acceptedFiles.length <= maxFilesAllowed) {
            setSelectedFiles([...selectedFiles, ...acceptedFiles]);
            const metaData = acceptedFiles.map((file) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        resolve({
                            name: file.name,
                            size: (file.size / 1024).toFixed(2), // KB
                            type: file.type,
                            width: img.width,
                            height: img.height,
                            progress: 0 // Progress bar for upload
                        });
                    };
                    img.src = URL.createObjectURL(file);
                });
            });

            // Resolve promises and set image settings
            Promise.all(metaData).then((data) => {
                setImageSettings(data);
            });
        } else {
            alert(`You can only upload a maximum of ${maxFilesAllowed} files.`);
        }
    };


  // Handle format change
  const handleFormatChange = (format, index) => {
    const updatedSettings = [...imageSettings];
    if (updatedSettings[index]) {
      updatedSettings[index].format = format;
      setImageSettings(updatedSettings);
    }
  };

  // Handle upload
  const handleUpload = (file, index) => {
      // Check if a format is selected
      if (!imageSettings[index]?.format || imageSettings[index]?.format === 'default') {
        alert('Please select an output format before converting.');
        return;
    }
    setUploading(true);
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress((prevState) => ({
        ...prevState,
        [file.name]: progress,
      }));
      if (progress >= 100) {
        clearInterval(interval);
        setUploading(false);
        convertToFormat(file, index);
      }
    }, 200);
  };

  const convertToFormat = async (file, index) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('format', imageSettings[index]?.format);

    try {
      const response = await fetch(myUrl, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Conversion failed with status: ${response.status}`);
      }

      const blob = await response.blob();

      const originalSize = (file.size / 1024 / 1024).toFixed(2);
      const compressedSize = (blob.size / 1024 / 1024).toFixed(2);
      const percentageSaved = Math.round(((originalSize - compressedSize) / originalSize) * 100);

      setCompressionData({
        originalSize,
        compressedSize,
        percentageSaved,
      });

      const objectURL = URL.createObjectURL(blob);
      downloadImage(objectURL, imageSettings[index]?.format);
    } catch (error) {
      console.error('Error during conversion:', error.message);
    }
  };

  const downloadImage = (url, format) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = `converted-image.${format}`;
    link.click();
    URL.revokeObjectURL(url);
    const count = localStorage.getItem('maxfilecount');
    localStorage.setItem('maxfilecount', count-1);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/jpeg': ['.jpg', '.jpeg'] }, // Only JPG & JPEG
    multiple: true,
    maxFiles: count,
});

  const getFilePreview = (file) => {
    const fileExtension = file.name.toLowerCase().split('.').pop();
    const iconMapping = {
      pdf: 'fa-file-pdf',
    };

    if (fileExtension === 'pdf') {
      return (
        <div className="pdf-preview">
          <p>{file.name}</p>
        </div>
      );
    }

    return iconMapping[fileExtension] ? (
      <i className={`fa ${iconMapping[fileExtension]} fa-3x`} />
    ) : (
      <img src={URL.createObjectURL(file)} alt="Preview" className="thumbnail" style={{ maxHeight: '100px' }} />
    );
  };

  return (
    <div className="image-converter-container">
      <h1>Convert Images</h1>
      <h2>Batch convert images to various formats online.</h2>

      <div className="upload-area" {...getRootProps()}>
        <input {...getInputProps()} />
        <h2>Choose your file</h2>
        <p>Drag and drop images or click to select files. (Max {count} files)</p>
      </div>

      <div className="image-settings-panel">
        {selectedFiles.map((file, index) => (
          <div key={index} className="thumbnail-container">
            {getFilePreview(file)}
            <div className="upload-progress">
              <label>Upload Progress</label>
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{
                    width: `${uploadProgress[file.name] || 0}%`,
                    backgroundColor: '#575052',
                    height: '10px',
                  }}
                ></div>
              </div>
              <p>{uploadProgress[file.name] || 0}%</p>
            </div>
            <div className="format-dropdown">
            <select
            value={imageSettings[index]?.format || 'default'}
            onChange={(event) => handleFormatChange(event.target.value, index)}
            className="output-format-select"
        >
            <option value="default" disabled>Select Format</option> {/* Default visible option */}
            {['PNG', 'GIF', 'TIFF', 'SVG', 'WEBP', 'HEIC', 'PDF', 'ICO'].map((format) => (
                <option key={format} value={format}>{format}</option>
            ))}
        </select>
            </div>
            <button
              onClick={() => handleUpload(file, index)}
              disabled={uploading}
              className="btn btn-primary"
            >
              Convert
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConvertFromJPG;
