import React, { useEffect , useState} from 'react';
import gsap from 'gsap';
import { useDropzone } from 'react-dropzone';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './ConvertToJPG.css'
import 'font-awesome/css/font-awesome.min.css';

gsap.registerPlugin(ScrollTrigger);
const ConvertToJPG = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageSettings, setImageSettings] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [compressionData, setCompressionData] = useState({});
    const [uploadComplete, setUploadComplete] = useState(false);
    const [showFileUpload, setShowFileUpload] = useState(true);
    const [uploading, setUploading] = useState(false); // Track uploading state
    const [pdfFile, setPdfFile] = useState(null); // For PDF preview
    const count = localStorage.getItem('maxfilecount');
   const [maxFilesAllowed, setMaxFilesAllowed] = useState(count); // Default to 10 for guests

    const myUrl = 'https://greatlobbyist.com/imageConverter/api/convert-to-jpg'
   // const myUrl = 'http://localhost:5000/convert-to-jpg'
  
      // Check if user is logged in (you can customize this with your JWT handling logic)
      const checkUserSession = () => {
        const token = localStorage.getItem('token'); // Assuming the token is stored here
        const sessionStartTime = localStorage.getItem('sessionStartTime');
        const currentTime = Date.now();
        const count = localStorage.getItem('maxfilecount');
        console.log(count)
        // If token exists and session is within 24 hours
        if (token && sessionStartTime && currentTime - sessionStartTime < 86400000) {
            // Logged-in user: Max files 15 per session
            setMaxFilesAllowed(count);
        } else {
            // Guest: Max files 10 per session
            setMaxFilesAllowed(count);
        }
    };
  
      // Update the session start time when user logs in
      const handleLogin = (token) => {
          localStorage.setItem('authToken', token);
          localStorage.setItem('sessionStartTime', Date.now().toString());
          checkUserSession();
      };
  
      useEffect(() => {
          checkUserSession();
      }, []);
  
      // Handle file drop
      const onDrop = (acceptedFiles) => {
          if (selectedFiles.length + acceptedFiles.length <= maxFilesAllowed) {
              setSelectedFiles([...selectedFiles, ...acceptedFiles]);
              const metaData = acceptedFiles.map((file) => {
                  return new Promise((resolve) => {
                      const img = new Image();
                      img.onload = () => {
                          resolve({
                              name: file.name,
                              size: (file.size / 1024).toFixed(2), // KB
                              type: file.type,
                              width: img.width,
                              height: img.height,
                              progress: 0 // Progress bar for upload
                          });
                      };
                      img.src = URL.createObjectURL(file);
                  });
              });
  
              // Resolve promises and set image settings
              Promise.all(metaData).then((data) => {
                  setImageSettings(data);
              });
          } else {
              alert(`You can only upload a maximum of ${maxFilesAllowed} files.`);
          }
      };
      

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
        maxFiles: 10, // Restrict the number of files to 10
    });

    // Handle compression selection
    const handleCompressionChange = (event, index) => {
        const newCompression = event.target.value;
        const updatedSettings = [...imageSettings];
        updatedSettings[index].compression = newCompression;
        setImageSettings(updatedSettings);
    };

    // Trigger upload and compression after selecting a percentage
    const handleUpload = (file, compression) => {
        setUploading(true); // Start the uploading process
        let progress = 0;
        const interval = setInterval(() => {
            progress += 5;
            setUploadProgress((prevState) => ({
                ...prevState,
                [file.name]: progress
            }));
            if (progress >= 100) {
                clearInterval(interval);
                setUploadComplete(true); // Set the upload as complete
                convertTojpg(file, compression);
            }
        }, 100); // Update every 100ms
    };

    const convertTojpg  = async (file) => {
        const formData = new FormData();
        formData.append('file', file); // Append the image file to FormData
    
        try {
            // Send the request to convert the image to JPG
            const response = await fetch(myUrl, {
                method: 'POST',
                body: formData,
            });
    
            // Check for a successful response
            if (!response.ok) {
                throw new Error(`Conversion failed with status: ${response.status}`);
            }
    
            // Handle the image response (as binary data)
            const blob = await response.blob();
    
            // Calculate the compression difference (original size vs compressed size)
            const originalSize = (file.size / 1024 / 1024).toFixed(2); // Convert to MB
            const compressedSize = (blob.size / 1024 / 1024).toFixed(2); // Convert to MB
    
            // Calculate the percentage of space saved
            const percentageSaved = Math.round(((originalSize - compressedSize) / originalSize) * 100);
    
            // Update the state for compression data
            setCompressionData({
                originalSize,
                compressedSize,
                percentageSaved,
            });
    
            // Create a temporary URL for the image file
            const objectURL = URL.createObjectURL(blob);
    
            // Download the converted image with the JPG extension
            downloadImage(objectURL, 'jpg');
    
        } catch (error) {
            console.error('Error during compression:', error.message);
            // Optionally, handle specific error messages or provide user feedback
        } finally {
            setUploading(false); // Stop uploading when the process is done
        }
    };
    
    // Function to download the converted image with dynamic file name
    const downloadImage = (url, extension) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = `jpgimage.${extension}`; // Use dynamic extension for the download file name
        link.click();
        URL.revokeObjectURL(url); // Clean up the URL after downloading
        const count = localStorage.getItem('maxfilecount');
        localStorage.setItem('maxfilecount', count-1);
    };

    const getFilePreview = (file) => {
        const fileExtension = file.name.toLowerCase().split('.').pop();
        const iconMapping = {
            pdf: 'fa-file-pdf',
            jpg: 'fa-file-image',
            png: 'fa-file-image',
            webp: 'fa-file-image',
        };
    
        console.log('File type:', file.type);
        console.log('Blob URL:', URL.createObjectURL(file));
    
        if (fileExtension === 'pdf') {
            return (
                <div className="pdf-preview">
                    <p>{file.name}</p>
                </div>
            );
        }
    
        if (iconMapping[fileExtension]) {
            return (
                <img
                    src={URL.createObjectURL(file)}
                    alt={`${fileExtension.toUpperCase()} Preview`}
                    className="thumbnail"
                    style={{ maxHeight: '100px', border: '1px solid #ccc' }}
                />
            );
        }
    
        return (
            <p>file type: {fileExtension}</p>
        );
    };

    const renderCircleProgress = (percentageSaved) => {
        const radius = 30;
        const circumference = 2 * Math.PI * radius;
        const offset = circumference - (percentageSaved / 100) * circumference;

        return (
            <svg width="80" height="80">
                <circle cx="40" cy="40" r={radius} stroke="#ddd" strokeWidth="5" fill="none" />
                <circle cx="40" cy="40" r={radius} stroke="#575052" strokeWidth="5" fill="none" strokeDasharray={circumference} strokeDashoffset={offset} />
                <text x="50%" y="50%" textAnchor="middle" stroke="#575052" strokeWidth="1px" dy=".3em">{percentageSaved}%</text>
            </svg>
        );
    };

    return (
        <div className="image-converter-container">
            <h1>Convert images to JPG</h1>
            <h2>Convert PNG, GIF, TIF, SVG, WEBP, HEIC, and RAW images to JPG format.
            Batch convert multiple images to JPG online in one go.</h2>

            <div>
                {showFileUpload && !uploading ? (
                    <div className="upload-area" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <h2>Choose your file</h2>
                        <p>Drag and drop your images or click to select files. (Max {count} files)</p>
                    </div>
                ) : null}
            </div>

            <div className="image-settings-panel">
                {selectedFiles.map((file, index) => (
                    <div key={index} className="thumbnail-container">
                        {getFilePreview(file)}
                        <div className="upload-progress">
                            <label>Upload Progress</label>
                            <div className="progress-bar-container">
                                <div
                                    className="progress-bar"
                                    style={{
                                        width: `${uploadProgress[file.name] || 0}%`,
                                        backgroundColor: '#575052',
                                        height: '10px',
                                    }}
                                ></div>
                            </div>
                            <p>{uploadProgress[file.name] || 0}%</p>
                        </div>
                        <button
                            onClick={() => handleUpload(file)}
                            disabled={uploading}
                            className="btn btn-primary"
                        >
                            Convert
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ConvertToJPG;
