import React, { useEffect } from 'react';
import gsap from 'gsap'; // Import GSAP
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // Import ScrollTrigger

gsap.registerPlugin(ScrollTrigger); // Register ScrollTrigger plugin

const Main = () => {
  useEffect(() => {
    // GSAP animation for elements on scroll
    gsap.fromTo(
      ".hero-header", 
      { opacity: 0 }, 
      { 
        opacity: 1, 
        duration: 1, 
        scrollTrigger: {
          trigger: ".hero-header",
          start: "top 80%", // Animation starts when 80% of the section enters the viewport
          end: "top 50%", // Ends when the top of the section reaches 50% of the viewport
          scrub: true, 
        }
      }
    );

    gsap.fromTo(
      ".display-4", 
      { x: -100, opacity: 0 }, 
      { 
        x: 0, 
        opacity: 1, 
        duration: 1, 
        scrollTrigger: {
          trigger: ".display-4",
          start: "top 90%", 
          end: "top 50%", 
          scrub: true, 
        }
      }
    );

    gsap.fromTo(
      ".fs-4", 
      { y: 50, opacity: 0 }, 
      { 
        y: 0, 
        opacity: 1, 
        duration: 1, 
        scrollTrigger: {
          trigger: ".fs-4", 
          start: "top 90%",
          end: "top 50%",
          scrub: true, 
        }
      }
    );

    gsap.fromTo(
      ".btn-primary", 
      { scale: 0 }, 
      { 
        scale: 1, 
        duration: 1, 
        scrollTrigger: {
          trigger: ".btn-primary", 
          start: "top 90%", 
          end: "top 50%", 
          scrub: true, 
        }
      }
    );

    gsap.fromTo(
      ".img-fluid", 
      { scale: 1.2, opacity: 0 }, 
      { 
        scale: 1, 
        opacity: 1, 
        duration: 1, 
        scrollTrigger: {
          trigger: ".img-fluid", 
          start: "top 90%", 
          end: "top 50%", 
          scrub: true, 
        }
      }
    );
  }, []);

  return (
    <div className="hero-header overflow-hidden px-5">
      <div className="rotate-img">
        <img src={`${process.env.PUBLIC_URL}/sty-1.png`} className="img-fluid w-100" alt="Background Style" />
        <div className="rotate-sty-2"></div>
      </div>
      <div className="row gy-5 align-items-center">
        <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
          <h1 className="display-4 text-dark mb-4 wow fadeInUp" data-wow-delay="0.3s">
            Turn Emails into Revenue
          </h1>
          <p className="fs-4 mb-4 wow fadeInUp" data-wow-delay="0.5s">
            Win new customers with the #1 email marketing and automations brand* that recommends ways to
            get more opens, clicks, and sales.
          </p>
          <a href="#" className="btn btn-primary rounded-pill py-3 px-5 wow fadeInUp" data-wow-delay="0.7s">
            Get Started
          </a>
        </div>
        <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.2s">
          <img src={`${process.env.PUBLIC_URL}/hero-img-1.png`} className="img-fluid w-100 h-100" alt="Hero" />
        </div>
      </div>
    </div>
  );
};

export default Main;
