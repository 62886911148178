// const setCanvasPreview = (
//   image, // HTMLImageElement
//   canvas, // HTMLCanvasElement
//   crop // PixelCrop
// ) => {
//   const ctx = canvas.getContext("2d");
//   if (!ctx) {
//     throw new Error("No 2d context");
//   }

//   // devicePixelRatio slightly increases sharpness on retina devices
//   // at the expense of slightly slower render times and needing to
//   // size the image back down if you want to download/upload and be
//   // true to the images natural size.
//   const pixelRatio = window.devicePixelRatio;
//   const scaleX = image.naturalWidth / image.width;
//   const scaleY = image.naturalHeight / image.height;

//   canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
//   canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

//   ctx.scale(pixelRatio, pixelRatio);
//   ctx.imageSmoothingQuality = "high";
//   ctx.save();

//   const cropX = crop.x * scaleX;
//   const cropY = crop.y * scaleY;

//   // Move the crop origin to the canvas origin (0,0)
//   ctx.translate(-cropX, -cropY);
//   ctx.drawImage(
//     image,
//     0,
//     0,
//     image.naturalWidth,
//     image.naturalHeight,
//     0,
//     0,
//     image.naturalWidth,
//     image.naturalHeight
//   );

//   ctx.restore();
// };
// export default setCanvasPreview;

const setCanvasPreview = (
  image, // HTMLImageElement
  canvas, // HTMLCanvasElement
  crop // PixelCrop
) => {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2D context");
  }

  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  // Calculate canvas dimensions
  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  console.log("Canvas Size:", canvas.width, canvas.height); // Log canvas size

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  // Calculate crop position
  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  console.log("Crop Values:", {
    cropX,
    cropY,
    cropWidth: crop.width * scaleX,
    cropHeight: crop.height * scaleY,
    originalWidth: image.naturalWidth,
    originalHeight: image.naturalHeight,
  }); // Log crop values

  // Clear the canvas before drawing
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Draw the cropped image onto the canvas
  ctx.drawImage(
    image,
    cropX, // Source X
    cropY, // Source Y
    crop.width * scaleX, // Source Width
    crop.height * scaleY, // Source Height
    0, // Destination X
    0, // Destination Y
    canvas.width / pixelRatio, // Destination Width
    canvas.height / pixelRatio // Destination Height
  );

  console.log("Image drawn on canvas successfully"); // Confirmation log
};


export default setCanvasPreview;
// const setCanvasPreview = (image, canvas, crop) => {
//   const ctx = canvas.getContext("2d");
//   if (!ctx) {
//     throw new Error("No 2d context");
//   }

//   const scaleX = image.naturalWidth / image.width;
//   const scaleY = image.naturalHeight / image.height;

//   canvas.width = crop.width * scaleX;
//   canvas.height = crop.height * scaleY;

//   ctx.imageSmoothingQuality = "high";

//   ctx.drawImage(
//     image,
//     crop.x * scaleX,
//     crop.y * scaleY,
//     crop.width * scaleX,
//     crop.height * scaleY,
//     0,
//     0,
//     canvas.width,
//     canvas.height
//   );
// };