import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Updated import
import AuthContext from '../../AuthContext';
import '../../style/style.css'; // Correct path to CSS
import '../../style/bootstrap.min.css'; // Correct path to CSS
const Header = () => {
  const navigate = useNavigate();
  const { user, logout, setUser } = useContext(AuthContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const sessionStartTime = localStorage.getItem('sessionStartTime');
    const sessionExpiryTime = localStorage.getItem('sessionExpiryTime');
    const  maxfile = localStorage.getItem('maxfilecount');
    const currentTime = new Date().getTime();    
    const startTime = new Date(Number(sessionStartTime));
    const expiryTime = new Date(Number(sessionExpiryTime));
  
   

    // Check if session has expired
    if (sessionStartTime && sessionExpiryTime && currentTime >= Number(sessionExpiryTime)) {
      logout(); // Log the user out if the session has expired
      const maxfile = localStorage.setItem('maxfilecount', 10);
     // navigate('/'); // Redirect to the home or login page
      // console.log("Session Start Time:", startTime);
      // console.log("Session Expiry Time:", expiryTime);
    
     
    }

    // Check if a valid token exists
  //   if (token) {
  //     try {
  //         const decoded = jwtDecode(token); // Decode the JWT token
  //         if (decoded) {
  //             setUser(decoded); // Save user data from decoded token
  //             localStorage.setItem('maxfilecount', 20);
  //         } else {
  //             console.error('User data missing from token');
  //             localStorage.setItem('maxfilecount', 10);
  //             logout();
  //         }
  //     } catch (error) {
  //         console.error('Invalid token', error);
  //         localStorage.setItem('maxfilecount', 10);
  //         logout(); // Log the user out if the token is invalid
  //     }
  // }

    // Update session start time and expiry time on first load if not set
    if (!sessionStartTime) {
      const newSessionStartTime = currentTime;
      const newSessionExpiryTime = newSessionStartTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      localStorage.setItem('sessionStartTime', newSessionStartTime);
      localStorage.setItem('sessionExpiryTime', newSessionExpiryTime);
    }

    if(!maxfile){
      const maxfile = localStorage.setItem('maxfilecount', 10);
    }
  }, [logout, navigate, setUser]); // Dependency array




  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from localStorage
    localStorage.removeItem('sessionStartTime'); // Clear session start time
    localStorage.removeItem('sessionExpiryTime'); // Clear session expiry time
    setUser(null); // Clear the user state
    navigate('/'); // Optionally, navigate to the home page or login page
  };

  return (
    <div className="container-fluid header position-relative overflow-hidden p-0">
      <nav className="navbar navbar-expand-lg fixed-top navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="/" className="navbar-brand p-0">
          <h1 className="display-6 text-primary m-0">
            <i className="fas fa-envelope me-3"></i>Image Converter
          </h1>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-label="Toggle navigation"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <a href="/" className="nav-item nav-link active">Home</a>
            <a href="/about" className="nav-item nav-link">About</a>
            <a href="/services" className="nav-item nav-link">Services</a>
            <div className="nav-item dropdown">
              <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                Pages
              </a>
              <div className="dropdown-menu m-0">
                <a href="/features" className="dropdown-item">Features</a>
                <a href="/pricing" className="dropdown-item">Pricing</a>
                <a href="/blog" className="dropdown-item">Blog</a>
                <a href="/testimonials" className="dropdown-item">Testimonials</a>
                <a href="/404" className="dropdown-item">404 Page</a>
              </div>
            </div>
            <a href="/contact" className="nav-item nav-link">Contact Us</a>
          </div>
          <div className="navbar-nav ms-auto">
  {user ? (
    <div className="nav-item dropdown">
      <a
        href="#"
        className="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        {user.profilePicture ? (
          <img
            src={user.profilePicture}
            alt="Profile"
            className="rounded-circle"
            style={{ width: '30px', height: '30px', objectFit: 'cover' }}
          />
        ) : (
          <i className="fas fa-user-circle"></i>
        )}
        {user.name}
      </a>
      <div className="dropdown-menu dropdown-menu-end">
        <a href="/profile" className="dropdown-item">Profile</a>
        <button className="dropdown-item" onClick={logout}>Logout</button>
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex">
        <Link to="/login" className="btn btn-light me-2">
          Log In
        </Link>
        <Link to="/signup" className="btn btn-light">
          Sign Up
        </Link>
      </div>
    </>
  )}
</div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
