import React, { useEffect } from "react";
import "./ServiceGrid.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompress,
  faExpand,
  faCrop,
  faFileImage,
  faExchangeAlt,
  faArrowUp,
  faMagic,
} from "@fortawesome/free-solid-svg-icons";
import { gsap } from "gsap";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const services = [
  {
    title: "Compress IMAGE",
    description: "Compress JPG, PNG, SVG, and GIFs while saving space and maintaining quality.",
    icon: faCompress,
    color: "#FF5733",
    link: "/compress", // React Router route
  },
  {
    title: "Resize IMAGE",
    description: "Define your dimensions, by percent or pixel, and resize your JPG, PNG, SVG, and GIF images.",
    icon: faExpand,
    color: "#33FF57",
    link: "/resize",
  },
  {
    title: "Convert to JPG",
    description: "Turn PNG, GIF, TIF, PSD, SVG, WEBP, HEIC, or RAW format images to JPG in bulk with ease.",
    icon: faFileImage,
    color: "#FF33A1",
    link: "/convert-to-jpg",
  },
  {
    title: "Convert from JPG",
    description: "Turn JPG images to PNG and GIF. Choose several JPGs to create an animated GIF in seconds!",
    icon: faExchangeAlt,
    color: "#FFD133",
    link: "/convert-from-jpg",
  },
  {
    title: "Crop IMAGE",
    description: "Crop JPG, PNG, or GIFs with ease; Choose pixels to define your rectangle or use our visual editor.",
    icon: faCrop,
    color: "#3375FF",
    link: "/crop",
  },
  {
    title: "Remove background",
    description: "Quickly remove image backgrounds with high accuracy. Instantly detect objects and cut out backgrounds with ease.",
    icon: faMagic,
    color: "#C0392B",
    link: "/remove-background",
  },
  {
    title: "Upscale Image",
    description: "Comming soon",
    icon: faArrowUp,
    color: "#16A085",
    link: "/",
  }
  
];

const ServiceGrid = () => {
  useEffect(() => {
    gsap.to(".service-card", {
      autoAlpha: 1,
      y: 0,
      duration: 0.6,
      stagger: 0.2,
      ease: "power2.out",
    });
  }, []);

  return (
    <div className="service-grid-container">
      <h1>All the tools you need for effortless bulk image editing.</h1>
      <p>Your go-to online photo editor, always free to use!</p>
      <div className="grid">
        {services.map((service, index) => (
          <Link
            key={index}
            to={service.link} // React Router's 'to' prop for navigation
            className="service-card"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="icon-container" style={{ color: service.color }}>
              <FontAwesomeIcon icon={service.icon} />
            </div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ServiceGrid;
