import React, { useState , useEffect} from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import './RemoveBackground.css';

const RemoveBackground = () => {
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [resultImage, setResultImage] = useState(null);
  const count = localStorage.getItem('maxfilecount');
  const [maxFilesAllowed, setMaxFilesAllowed] = useState(count); // Default to 10 for guests
     const myUrl = "https://greatlobbyist.com/imageConverter/api/remove-bg"
     //const myUrl = 'http://localhost:5000/remove-bg'
     // alert(count)
      // Check if user is logged in (you can customize this with your JWT handling logic)
      const checkUserSession = () => {
          const token = localStorage.getItem('token'); // Assuming the token is stored here
          const sessionStartTime = localStorage.getItem('sessionStartTime');
          const currentTime = Date.now();
          const count = localStorage.getItem('maxfilecount');
          console.log(count)
          // If token exists and session is within 24 hours
          if (token && sessionStartTime && currentTime - sessionStartTime < 86400000) {
              // Logged-in user: Max files 15 per session
              setMaxFilesAllowed(count);
          } else {
              // Guest: Max files 10 per session
              setMaxFilesAllowed(count);
          }
      };

       useEffect(() => {
              checkUserSession();
          }, []); 

          

  // Handle file drop
  const onDrop = async (acceptedFiles) => {
    if(count <= 0){
      alert('free limit has been exeeded');
      return;
  }
    setLoading(false); // Reset loading state on new upload
    setError("");
    setResultImage(null);

    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // Setup drag-and-drop functionality using react-dropzone
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.png,.jpg,.jpeg,.webp', // Allow only image files
    multiple: false,
  });

  // Function to remove the background using your custom API
  const removeBackground = async () => {
    if (!imageSrc) {
      setError("Please upload an image first.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const formData = new FormData();
      const fileBlob = await fetch(imageSrc).then((res) => res.blob());
      formData.append("image_file", fileBlob);

      // Replace this URL with your API endpoint
      const response = await axios.post(
        myUrl, // Your API endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Required for file uploads
          },
          responseType: "blob", // Expecting binary data (image file) in response
        }
      );

      const url = URL.createObjectURL(response.data);
      setResultImage(url);

      // Trigger automatic download
      const a = document.createElement("a");
      a.href = url;
      a.download = "image-without-background.png"; // Set the default download file name
      a.click();
      const count = localStorage.getItem('maxfilecount');
      localStorage.setItem('maxfilecount', count-1);
    } catch (error) {
      console.error("Error:", error.response || error.message);
      setError("Failed to remove background. Please try again.");
    } finally {
      setLoading(false); // Reset loading state after the process
    }
  };

  return (
    <div className="image-background-remover">
      <h2 style={{ textAlign: "center" }}>Remove Background from Image</h2>

      <div className="upload-area" {...getRootProps()}>
        <input {...getInputProps()} />
        <h2>Choose your file</h2>
        <p>Drag and drop your image or click to select a file</p>
      </div>

      {imageSrc && (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <h3>Original Image</h3>
          <img
            src={imageSrc}
            alt="Uploaded"
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
              objectFit: "contain",
              marginBottom: "20px",
            }}
          />
        </div>
      )}

      {error && <p style={{ color: "red" }}>{error}</p>}

      <button
        onClick={removeBackground}
        disabled={loading} // Disable button while loading
        style={{
          backgroundColor: "#575052",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          display: "block",
          margin: "0 auto",
        }}
      >
        {loading ? "Removing..." : "Remove Background"} {/* Change button text based on loading state */}
      </button>

      {resultImage && (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <h3>Image with Background Removed</h3>
          <img
            src={resultImage}
            alt="Result"
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
              objectFit: "contain",
              marginBottom: "20px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default RemoveBackground;