import React, { useState, useContext, useEffect } from 'react';
import '../signup/signup.css';
import 'font-awesome/css/font-awesome.min.css';
import loginImage from '../../signin-image.jpg'; // Ensure the path to the image is correct
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import AuthContext from '../../AuthContext';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import {jwtDecode} from "jwt-decode";
const clientId = "220686903254-l97vfmmi261o095u5emahgfiora93ua0.apps.googleusercontent.com"; // Replace with your Client ID
const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  const { login } = useContext(AuthContext);

  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem('token'); // or check your preferred session storage
    if (token) {
      navigate('/'); // Redirect to the home page if logged in
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!credentials.email) {
      errors.email = 'Username is required';
    }
    if (!credentials.password) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1111603826928978', // Replace with your actual Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v10.0',
      });
    };

    // Load the Facebook SDK script
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: clientId, // Replace with your actual Google Client ID
        callback: handleCredentialResponse,
      });

      // Render the button
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInDiv"),
        {
          type: "image", // Show only the icon
          theme: "outline", // Outline theme
          size: "large", // Adjust size if needed
        }
      );

      // Optional: Prompt the user to sign in
      window.google.accounts.id.prompt();
    };

    if (typeof window.google === "undefined") {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    } else {
      initializeGoogleSignIn();
    }
  }, []);

  const handleCredentialResponse = async (response) => {
    console.log("Google Sign-In Success:", response);
  
    try {
      // Decode the JWT to extract user information
      const decodedToken = jwtDecode(response.credential);
      console.log("Decoded Token:", decodedToken);
  
      // Prepare social media data for login
      const socialMediaData = {
        name: decodedToken.name,
        email: decodedToken.email,
        social_media_id: decodedToken.sub, // Google user ID
      };
  
      console.log("Prepared Social Media Data:", socialMediaData);
  
      // Call the login function with social media data
      await handleLogin(null, socialMediaData); // Pass the social media data to login
    } catch (error) {
      console.error("Error handling Google Sign-In response:", error);
      setAlert({
        type: "error",
        message: "Google Sign-In failed. Please try again.",
      });
    }
  };
 
  const handleFacebookLogin = () => {
    window.FB.login(response => {
      if (response.authResponse) {
        handleFacebookResponse(response);
      }
    });
  };

  const handleFacebookResponse = async (response) => {
    console.log('Facebook Login Success:', response);

    if (response.status === 'connected') {
      const { userID, accessToken } = response.authResponse;

      try {
        // Fetch user details from Facebook Graph API
        const userProfileResponse = await axios.get(
          `https://graph.facebook.com/${userID}?fields=name,email&access_token=${accessToken}`
        );
        const userProfile = userProfileResponse.data;

        // Prepare social media data for signup/login
        const socialMediaData = {
          name: userProfile.name,
          email: userProfile.email,
          social_media_id: userID,
        };

        // Call login function with social media data
        handleLogin(null, socialMediaData); // Pass the social media data to login
      } catch (error) {
        console.error('Error fetching Facebook user profile:', error);
        setAlert({
          type: 'error',
          message: 'Failed to fetch Facebook user data. Please try again.',
        });
      }
    } else {
      setAlert({
        type: 'error',
        message: 'Facebook login failed. Please try again.',
      });
    }
  };

  const handleLogin = async (e, socialMediaData = null) => {
    // Prevent form submission if it's a manual login
    if (e) e.preventDefault();

    // Validate the form if it's a manual login (not from social media)
    if (!socialMediaData) {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
    }

    try {
      // Prepare the login data (either manual or social media login)
      const loginData = socialMediaData || credentials;

      // Send POST request to the backend API for login
      const response = await axios.post('https://greatlobbyist.com/imageConverter/api/auth/login', loginData);

      // If the login is successful, store the JWT token in localStorage
      localStorage.setItem('token', response.data.token);

      // Display success alert
      setAlert({ type: 'success', message: 'Login successful!' });
      login(response.data.token); // Call the login function with the received token

      // Redirect to the home page or a protected route
      navigate('/');
    } catch (error) {
      // Handle login failure
      setAlert({ type: 'error', message: 'Login failed. Please check your credentials.' });
    }
  };

  return (
    <div className="main">
      <section className="sign-in">
        <div className="container">
          <div className="signin-content">
            <div className="signin-image">
              <figure>
                <img src={loginImage} alt="Sign up" />
              </figure>
              <Link to="/signup" className="rounded-pill py-2 px-4">
                Create an account
              </Link>
            </div>

            <div className="signin-form">
              <h2 className="form-title">Login</h2>
              {alert && <div className={`alert ${alert.type}`}>{alert.message}</div>}
              <form method="POST" className="register-form" id="login-form" onSubmit={(e) => handleLogin(e)}>
                <div className="form-group">
                  <label htmlFor="your_name">
                    <i className="zmdi zmdi-account material-icons-name"></i>
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="your_name"
                    placeholder="Your Email"
                    value={credentials.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && <p className="error-text">{errors.email}</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="your_pass">
                    <i className="zmdi zmdi-lock"></i>
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="your_pass"
                    placeholder="Password"
                    value={credentials.password}
                    onChange={handleInputChange}
                  />
                  {errors.password && <p className="error-text">{errors.password}</p>}
                </div>

                <div className="form-group">
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="agree-term"
                  />
                  <label htmlFor="remember-me" className="label-agree-term">
                    <span>
                      <span></span>
                    </span>
                    Remember me
                  </label>
                </div>

                <div className="form-group form-button">
                  <input
                    type="submit"
                    name="signin"
                    id="signin"
                    className="form-submit"
                    value="Log in"
                  />
                </div>
              </form>

              <div className="social-login">
                {/* <span className="social-label">Or login with</span> */}
                <ul className="socials">  
                  <li>
                    <a href="#" onClick={handleCredentialResponse} id="googleSignInDiv">
                      <i className="fa fa-google" ></i>
                    </a>
                  </li>
                  <li>                   
                     <div><div onClick={handleFacebookLogin} tabindex="0" role="button" aria-labelledby="button-label" class="nsm7Bb-HzV7m-LgbsSe  hJDwNd-SxQuSe i5vt6e-Ia7Qfc uaxL4e-RbRzK"><div class="nsm7Bb-HzV7m-LgbsSe-MJoBVe"></div><div class="nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb "><div class="nsm7Bb-HzV7m-LgbsSe-Bz112c"> <i className="fa fa-facebook"></i></div><span class="nsm7Bb-HzV7m-LgbsSe-BPrWId">Sign in with Facebook</span><span class="L6cTce" id="button-label">Sign in with Facebook</span></div></div></div>
                   </li>                
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
