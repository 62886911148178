import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './Compress.css';

const Compress = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageSettings, setImageSettings] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [compressionData, setCompressionData] = useState({});
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploading, setUploading] = useState(false);
    const count = localStorage.getItem('maxfilecount');
    const [maxFilesAllowed, setMaxFilesAllowed] = useState(count); // Default to 10 for guests

    const myUrl = 'https://greatlobbyist.com/imageConverter/api/compress'
   // const myUrl = 'http://localhost:5000/compress'
   // alert(count)
    // Check if user is logged in (you can customize this with your JWT handling logic)
    const checkUserSession = () => {
        const token = localStorage.getItem('token'); // Assuming the token is stored here
        const sessionStartTime = localStorage.getItem('sessionStartTime');
        const currentTime = Date.now();
        const count = localStorage.getItem('maxfilecount');
        console.log(count)
        // If token exists and session is within 24 hours
        if (token && sessionStartTime && currentTime - sessionStartTime < 86400000) {
            // Logged-in user: Max files 15 per session
            setMaxFilesAllowed(count);
        } else {
            // Guest: Max files 10 per session
            setMaxFilesAllowed(count);
        }
    };

    // Update the session start time when user logs in
    const handleLogin = (token) => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('sessionStartTime', Date.now().toString());
        checkUserSession();
    };

    useEffect(() => {
        checkUserSession();
    }, []);

    // Handle file drop
    const onDrop = (acceptedFiles) => {
        console.log(count)
        // Filter out any files that are already selected
        const newFiles = acceptedFiles.filter((file) => {
            return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
        });
    
        // If there are no new files to add, show an alert
        if (newFiles.length === 0) {
            alert('This file has already been selected.');
            return;
        }

        if(count <= 0){
            alert('free limit has been exeeded');
            return;
        }
    
        if (selectedFiles.length + newFiles.length <= maxFilesAllowed) {
            setSelectedFiles([...selectedFiles, ...newFiles]);
    
            const metaData = newFiles.map((file) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        resolve({
                            name: file.name,
                            size: (file.size / 1024).toFixed(2), // KB
                            type: file.type,
                            width: img.width,
                            height: img.height,
                            progress: 0, // Progress bar for upload
                            compression: "85", // Default compression setting
                        });
                    };
                    img.src = URL.createObjectURL(file);
                });
            });
    
            // Resolve promises and set image settings
            Promise.all(metaData).then((data) => {
                setImageSettings((prevSettings) => [...prevSettings, ...data]);
            });
        } else {
            alert(`free limit has been exeeded`);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.png,.jpg,.jpeg,.webp',  // Allow only these file types
        multiple: true,
        maxFiles: count,
    });

    // Handle compression selection
    const handleCompressionChange = (event, index) => {
        const newCompression = event.target.value;
        const updatedSettings = [...imageSettings];
        
        // Ensure the index exists and update compression setting
        if (updatedSettings[index]) {
            updatedSettings[index].compression = newCompression;
            setImageSettings(updatedSettings);
        }
    };

    // Trigger upload and compression after selecting a percentage
    const handleUpload = (file, compression) => {
        setUploading((prev) => ({
            ...prev,
            [file.name]: 'compressing', // Mark this file as compressing
        }));
    
        let progress = 0;
        const interval = setInterval(() => {
            progress += 5;
            setUploadProgress((prevState) => ({
                ...prevState,
                [file.name]: progress,
            }));
    
            if (progress >= 100) {
                clearInterval(interval);
                compressImage(file, compression);
            }
        }, 100); // Update every 100ms
    };

    const compressImage = async (file, compression) => {
        const formData = new FormData();
        formData.append('file', file);  // The image file to upload
        formData.append('compression', parseInt(compression, 10));  // Ensure compression is an integer
        formData.append('format', file.type);  // Send MIME type explicitly
    
        try {
            const response = await fetch(myUrl, {
                method: 'POST',
               
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Compression failed');
            }
    
            // Handle the image response (not as JSON)
            const blob = await response.blob();  // Get the binary image response
    
            // Calculate the compression difference (original size vs compressed size)
            const originalSize = file.size / 1024 / 1024; // MB
            const compressedSize = blob.size / 1024 / 1024; // MB
    
            setCompressionData({
                originalSize: originalSize.toFixed(2),
                compressedSize: compressedSize.toFixed(2),
                percentageSaved: Math.round(((originalSize - compressedSize) / originalSize) * 100)
            });
    
            // Check if the compressed file is larger than the original file size
            if (compressedSize >= originalSize) {
                // If the compressed file is larger, show a message instead of downloading
                setCompressionData((prevData) => ({
                    ...prevData,
                    message: "The image is already compressed, no download triggered."
                }));
            } else {
                // Create a temporary URL for the image file
                const objectURL = URL.createObjectURL(blob);
    
                // Download with dynamic extension based on MIME type
                const fileExtension = file.type.split('/')[1]; // Get file extension (jpg, png, etc.)
                downloadImage(objectURL, fileExtension);
            }
        } catch (error) {
            console.error('Error during compression:', error);
        } finally {
            setUploading(false); // Stop uploading after compression
        }
    };

    // Function to download the compressed image with dynamic extension
    const downloadImage = (url, extension) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = `compressed-image.${extension}`;
        link.click();
        URL.revokeObjectURL(url);  // Clean up the object URL after downloading
        const count = localStorage.getItem('maxfilecount');
        localStorage.setItem('maxfilecount', count-1);
      
    };

    const renderCircleProgress = (percentageSaved) => {
        const radius = 30;
        const circumference = 2 * Math.PI * radius;
        const offset = circumference - (percentageSaved / 100) * circumference;

        return (
            <svg width="80" height="80">
                <circle cx="40" cy="40" r={radius} stroke="#ddd" strokeWidth="5" fill="none" />
                <circle cx="40" cy="40" r={radius} stroke="#575052" strokeWidth="5" fill="none" strokeDasharray={circumference} strokeDashoffset={offset} />
                <text x="50%" y="50%" textAnchor="middle" stroke="#575052" strokeWidth="1px" dy=".3em">{percentageSaved}%</text>
            </svg>
        );
    };

    return (
        <div className="image-converter-container">
            <h1>Compress IMAGE</h1>
            <h3>Compress JPG , WEBP or PNG with the best quality and compression. Reduce the filesize of your images at once.</h3>

            <div>
                <div className="upload-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <h2>Choose your file</h2>
                    <p>Drag and drop your images or click to select files.</p>
                </div>
            </div>

            <div className="image-settings-panel">
                {selectedFiles.length > 0 && !uploading ? (
                    <h3>Selected Images</h3>
                ) : null}

                {selectedFiles.map((file, index) => {
                    const imgSrc = URL.createObjectURL(file);
                    const settings = imageSettings[index] || {};
                    const trimmedName = file.name.length > 20 ? file.name.slice(0, 20) + '...' : file.name;

                    return (
                        <div key={index} className="thumbnail-container">
                            <img
                                src={imgSrc}
                                alt={`Preview ${index + 1}`}
                                className="thumbnail"
                                style={{ maxHeight: '100px' }}
                            />
                            <div className="image-options">
                                <div className="upload-progress">
                                    <label>Upload Progress</label>
                                    <div className="progress-bar-container">
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${uploadProgress[file.name] || 0}%`,
                                                backgroundColor: '#575052',
                                                height: '10px',
                                            }}
                                        ></div>
                                    </div>
                                    <p>{uploadProgress[file.name] || 0}%</p>
                                </div>

                                {/* Compression options */}
                                <div className="compression-options">
                                    <label>Compression</label>
                                    <select
                                        value={settings.compression}
                                        onChange={(e) => handleCompressionChange(e, index)}
                                    >
                                        <option >Choose Option</option>
                                        <option value="85">Low Compression</option>
                                        <option value="70">Medium Compression (Good Quality, Smaller File)</option>
                                        <option value="50">High Compression (Smaller File)</option>
                                    </select>
                                </div>

                                {/* Upload Button */}
                                <div>
                                    {/* <button onClick={() => handleUpload(file, settings.compression)}>
                                        {uploading ? "Compressing..." : "Compress"}
                                    </button> */}
                                    <a 
        onClick={() => handleUpload(file, settings.compression)} 
        className="btn btn-light border border-primary rounded-pill text-primary py-2 px-4 mt-4"
        disabled={uploading[file.name] === 'compressing'}  // Disable button during compression
    >
        {uploading[file.name] === 'compressing' ? "Compressing..." : "Compress"}
    </a>
                                 </div>                                 
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* Display compression result */}
            {compressionData.originalSize && (
                <div className="compression-result">
                    <p>
                        Original Size: {compressionData.originalSize} MB <br />
                        Compressed Size: {compressionData.compressedSize} MB
                    </p>

                    {/* Show saving percentage */}
                    {compressionData.percentageSaved && (
                        <div>
                            <h3>Saved: {compressionData.percentageSaved}%</h3>
                            {renderCircleProgress(compressionData.percentageSaved)}
                        </div>
                    )}

                    {/* Show message if the image is already compressed */}
                    {compressionData.message && <p>{compressionData.message}</p>}
                </div>
            )}
        </div>
    );
};

export default Compress;
