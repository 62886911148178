import React, { useState, useRef , useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from "react-image-crop";
import setCanvasPreview from "../../setCanvasPreview";
import './ImageCropper.css';
import "react-image-crop/dist/ReactCrop.css";
import { float } from "three/tsl";

const ASPECT_RATIO = 1;  // Keep this to ensure square crop
const MIN_DIMENSION = 150;

const ImageCropper = ({ closeModal, updateAvatar }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const fileInputRef = useRef(null);  // Reference for the hidden file input
  const [imgSrc, setImgSrc] = useState("");
  // const [crop, setCrop] = useState();
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100, // Set the width to 100%
    height: 100, // Set the height as needed
    x: 0, // Set left position to 0%
    y: 0, // Set top position as needed
  });
  const [error, setError] = useState("");
  const [isCropped, setIsCropped] = useState(false); // To track if the image has been cropped
  const [imageSettings, setImageSettings] = useState([]);
  const [fileExtension, setFileExtension] = useState(""); // State to store file extension (e.g., jpeg, png)
    const count = localStorage.getItem('maxfilecount');
    const [maxFilesAllowed, setMaxFilesAllowed] = useState(count); // Default to 10 for guests
    const myUrl = 'https://greatlobbyist.com/imageConverter/api/compress'
   //const myUrl = 'http://localhost:5000/compress'
   // alert(count)
    // Check if user is logged in (you can customize this with your JWT handling logic)
    const checkUserSession = () => {
        const token = localStorage.getItem('token'); // Assuming the token is stored here
        const sessionStartTime = localStorage.getItem('sessionStartTime');
        const currentTime = Date.now();
        const count = localStorage.getItem('maxfilecount');
        console.log(count)
        // If token exists and session is within 24 hours
        if (token && sessionStartTime && currentTime - sessionStartTime < 86400000) {
            // Logged-in user: Max files 15 per session
            setMaxFilesAllowed(count);
        } else {
            // Guest: Max files 10 per session
            setMaxFilesAllowed(count);
        }
    };

       useEffect(() => {
                checkUserSession();
            }, []);

  // Drag and Drop using react-dropzone
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]; // Assuming only one file is dropped
      handleFileUpload(file);
    },
    multiple: false,
    accept: '.png,.jpg,.jpeg,.webp',   // Restrict to images only
    maxFiles: count,
  });


  const handleFileUpload = (file) => {
    if (count <= 0) {
      setError("You cannot upload more files.");
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
  
      imageElement.addEventListener("load", (e) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;
        console.log("Image Loaded:", { naturalWidth, naturalHeight, fileType: file.type });
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("Image must be at least 150 x 150 pixels.");
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
      setFileExtension(file.name.split(".").pop());
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleCropAndDownload = () => {
  
    if (imgRef.current && crop && previewCanvasRef.current) {
      // Set canvas preview for cropped area
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );

      // Create download link
      const dataUrl = previewCanvasRef.current.toDataURL(`image/${fileExtension}`); // Use the detected file extension
      const a = document.createElement("a");
      a.href = dataUrl;
      a.download = `cropped-image.${fileExtension}`; // Download with the same extension as the original file
      a.click();
      const count = localStorage.getItem('maxfilecount');
      localStorage.setItem('maxfilecount', count-1);
      setIsCropped(true); // Mark the image as cropped
    }
  };

  return (
    <>
      {/* Drag-and-drop area */}
      <div className="image-converter-container">
        <h1>Image Crop Online</h1>
        <h2>Crop your files quickly and easily, all online.</h2>
  
        <div
          className="upload-area"
          {...getRootProps()}
          style={{
            border: "2px dashed #ccc",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
            maxWidth: "100%",
          }}
        >
          <input {...getInputProps()} />
          <h2>Choose your file</h2>
          <p>Drag and drop your images or click to select files.</p>
        </div>
      </div>
  
      {error && <p className="text-red-400 text-xs">{error}</p>}
  
      {imgSrc && (
        <div
          className="relative flex flex-col items-center justify-center"
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column", // Ensure content is arranged vertically
            alignItems: "center", // Horizontally center child elements
            justifyContent: "center", // Vertically center child elements
          }}
        >
          {/* Crop Container */}
          <div
            className="crop-container"
            style={{
              position: "relative",
              width: "50%",
              left:"0% !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px", // Space between image and button
            }}
          >
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}            
              circularCrop={false} // Ensure crop is square
              keepSelection
              // aspect={ASPECT_RATIO}
              minWidth={MIN_DIMENSION}
            >
              <img
                ref={imgRef}
                src={imgSrc}
                alt="Upload"
                style={{
                  maxHeight: "70vh",
                  width: "auto", // Ensure the image maintains its aspect ratio
                  position: "relative",
                  display: "block",
                  margin: "auto", // Center the image inside its parent container
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
  
          {/* Button */}
          <button
            className="text-white font-mono text-xs py-2 px-4 rounded-2xl mt-4 bg-sky-500 hover:bg-sky-600"
            onClick={handleCropAndDownload} // Single button for crop and download
            style={{
              marginTop: "20px", // Ensure some space above the button
              display: "block",
              marginLeft: "auto",
              marginRight: "auto", // Center the button horizontally
            }}
          >
            {isCropped ? "Download Cropped Image" : "Crop Image"}
          </button>
        </div>
      )}
  
      {/* Hidden file input for click-to-upload */}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={(e) => handleFileUpload(e.target.files?.[0])}
        style={{ display: "none" }} // Hide the input element
      />
  
      {/* Preview Canvas */}
      {crop && (
        <div className="mt-4">
          <canvas
            ref={previewCanvasRef}
            style={{
              display: "block",
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
              margin: "auto",
              maxWidth: "200px",
              height: "200px",
              backgroundColor: "#f7f7f7",
            }}
          />
        </div>
      )}
    </>
  );
};

export default ImageCropper;
