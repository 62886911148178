import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Main from './components/main/Main';
import ServiceGrid from './components/ServiceGrid/ServiceGrid';
import OurService from './components/service/OurService';
import ImageComparison from './components/ImageComparison/ImageComparison';

// Import service pages
import Compress from './components/Compress/Compress';
import Resize from './components/Resize/Resize';
import ConvertToJPG from './components/ConvertToJPG/ConvertToJPG';
import ConvertFromJPG from './components/ConvertFromJPG/ConvertFromJPG';
import Crop from './components/Crop/Crop';
import Upscale from './components/Upscale/Upscale';
import RemoveBackground from './components/RemoveBackground/RemoveBackground';
import ImageCropper  from './components/ImageCropper/ImageCropper';
import Signup from './components/signup/signup';
import Login from './components/Login/Login';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        {/* Main route */}
        <Route
          path="/"
          element={
            <>
              <ServiceGrid />
              <ImageComparison />
              <Main />
              <OurService />
            </>
          }
        />
        {/* Service routes */}
        <Route path="/compress" element={<Compress />} />
        <Route path="/resize" element={<Resize />} />
        <Route path="/convert-to-jpg" element={<ConvertToJPG />} />
        <Route path="/convert-from-jpg" element={<ConvertFromJPG />} />
        {/* <Route path="/remove-background" element={<RemoveBackground />} /> */}
        <Route path="/crop" element={<ImageCropper />} />
        <Route path="/upscale" element={<Upscale />} />
        <Route path="/remove-background" element={<RemoveBackground />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
