import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'; // Import axios for API calls
import "./signup.css";
import "font-awesome/css/font-awesome.min.css";
import signupImage from "../../signup-image.jpg";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import AuthContext from '../../AuthContext';
import {jwtDecode} from "jwt-decode";

const clientId = "220686903254-l97vfmmi261o095u5emahgfiora93ua0.apps.googleusercontent.com"; // Replace with your Client ID

const Signup = () => {
  const { login } = useContext(AuthContext);
    const navigate = useNavigate();
  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem('token'); // or check your preferred session storage
    if (token) {
      navigate('/'); // Redirect to the home page if logged in
    }
  }, [navigate]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null); // State for alert messages

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  // Simple validation function
  const validate = () => {
    const newErrors = {};
    if (!userData.name) newErrors.name = "Name is required";
    if (!userData.email) newErrors.email = "Email is required";
    if (!userData.password) newErrors.password = "Password is required";
    else if (userData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters";
    
    return newErrors;
  };

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : '1111603826928978',  // Replace with your actual Facebook App ID
        cookie     : true,
        xfbml      : true,
        version    : 'v10.0'
      });
    };

    // Load the Facebook SDK script
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

   useEffect(() => {
      const initializeGoogleSignIn = () => {
        window.google.accounts.id.initialize({
          client_id: clientId, // Replace with your actual Google Client ID
          callback: handleCredentialResponse,
        });
  
        // Render the button
        window.google.accounts.id.renderButton(
          document.getElementById("googleSignInDiv"),
          {
            type: "image", // Show only the icon
            theme: "outline", // Outline theme
            size: "large", // Adjust size if needed
          }
        );
  
        // Optional: Prompt the user to sign in
        window.google.accounts.id.prompt();
      };
  
      if (typeof window.google === "undefined") {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = initializeGoogleSignIn;
        document.body.appendChild(script);
      } else {
        initializeGoogleSignIn();
      }
    }, []);
    const handleCredentialResponse = async (response) => {
      try {
        console.log("Google Sign-In Success:", response);
    
        // Decode the JWT to extract user information
        const decodedToken = jwtDecode(response.credential);
        console.log("Decoded Token:", decodedToken);
    
        const socialMediaData = {
          name: decodedToken.name,
          email: decodedToken.email || null, // Handle missing email
          socialMediaId: decodedToken.sub, // Google user ID
        };
    
        console.log("Prepared Social Media Data:", socialMediaData);
    
        if (!socialMediaData.email) {
          setAlert({
            type: "info",
            message: "We couldn't retrieve your email. Please provide it manually.",
          });
          // Optionally, show a modal or input form for email entry
          return;
        }
    
        // Call signup function with social media data
        handleSignup(socialMediaData);
      } catch (error) {
        console.error("Error handling Google Sign-In response:", error);
        setAlert({
          type: "error",
          message: "Google Sign-In failed. Please try again.",
        });
      }
    };

  const handleFacebookResponse = async (response) => {
    console.log("Facebook Login Success:", response);
  
    if (response.status === "connected") {
      const { userID, accessToken } = response.authResponse;
  
      try {
        // Fetch user details from Facebook Graph API
        const userProfileResponse = await axios.get(
          `https://graph.facebook.com/${userID}?fields=name,email&access_token=${accessToken}`
        );
  
        const userProfile = userProfileResponse.data;
  
        // Prepare social media data for signup
        const socialMediaData = {
          name: userProfile.name,
          email: userProfile.email || null, // Handle missing email
          socialMediaId: userID,
        };

        console.log(socialMediaData)
  
        if (!socialMediaData.email) {
          setAlert({
            type: "info",
            message: "We couldn't retrieve your email. Please provide it manually.",
          });
          // Optionally, show a modal or input form for email entry
          return;
        }
  
        // Call signup function with social media data
        handleSignup(socialMediaData);
      
      } catch (error) {
        console.error("Error fetching Facebook user profile:", error.response || error.message);
  
        setAlert({
          type: "error",
          message:
            error.response?.data?.error?.message ||
            "Failed to fetch Facebook user data. Please try again.",
        });
      }
    } else {
      setAlert({
        type: "error",
        message: "Facebook login failed. Please try again.",
      });
    }
  };
  


  const handleFacebookLogin = () => {
    window.FB.login(response => {
      if (response.authResponse) {
        handleFacebookResponse(response);
      }
    });
  };

  const handleSignup = async (socialMediaData = {}) => {
    const { name, email, password } = userData; // User data from form (if applicable)
    const data = {
      name,
      email,
      password,
      ...socialMediaData, // Merge social media data
    };
  
    try {
      const response = await axios.post("https://greatlobbyist.com/imageConverter/api/auth/register", data);
      console.log(response.data);
      setAlert({ type: "success", message: "Registration successful!" });
      if(!socialMediaData){
        setTimeout(() => {
          navigate('/login'); // Redirect to login page
        }, 2000);
      }else{
        login(response.data.token);
          navigate('/');
      }
    
    } catch (error) {
      console.error("Error during registration", error);
      if (error.response && error.response.data) {
        setAlert({
          type: "error",
          message: error.response.data.message || "Registration failed. Please try again.",
        });
      } else {
        setAlert({
          type: "error",
          message: "Registration failed. Please try again.",
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      handleSignup();
    } else {
      setErrors(formErrors);
    }
  };

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 3000); // Auto hide the alert after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [alert]);

  return (
    <div className="main">
      <section className="signup">
        <div className="container">
          <div className="signup-content">
            <div className="signup-form">
              <h2 className="form-title">Sign up</h2>
              {alert && (
                <div
                  className={`alert ${alert.type === "success" ? "alert-success" : "alert-error"}`}
                >
                  {alert.message}
                </div>
              )}
              <form method="POST" className="register-form" onSubmit={handleSubmit} id="register-form">
                <div className="form-group">
                  <label htmlFor="name">
                    <i className="zmdi zmdi-account material-icons-name"></i>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={userData.name}
                    onChange={handleInputChange}
                    id="name"
                    placeholder="Your Name"
                  />
                  {errors.name && <span className="error-message">{errors.name}</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    <i className="zmdi zmdi-email"></i>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                    id="email"
                    placeholder="Your Email"
                  />
                  {errors.email && <span className="error-message">{errors.email}</span>}
                </div>
                <div className="form-group">
                  <label htmlFor="pass">
                    <i className="zmdi zmdi-lock"></i>
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={userData.password}
                    onChange={handleInputChange}
                    id="pass"
                    placeholder="Password"
                  />
                  {errors.password && <span className="error-message">{errors.password}</span>}
                </div>
                <div className="form-group">
                  <input
                    type="checkbox"
                    name="agree-term"
                    id="agree-term"
                    className="agree-term"
                  />
                  <label htmlFor="agree-term" className="label-agree-term">
                    <span>
                      <span></span>
                    </span>
                    I agree to all statements in{" "}
                    <a href="#" className="term-service">
                      Terms of service
                    </a>
                  </label>
                </div>
                <div className="form-group form-button">
                  <input
                    type="submit"
                    name="signup"
                    id="signup"
                    className="form-submit"
                    value="Register"
                  />
                </div>
              </form>

              <div className="social-login">
                <div>
                  {/* <span className="social-label">Or login with</span> */}
                  <ul className="socials">
                    <li>                    
                      <a href="#" onClick={handleCredentialResponse} id="googleSignInDiv">
                      <i className="fa fa-google" ></i>
                    </a>
                    </li>
                    <li>
                    
                       <div><div onClick={handleFacebookLogin} tabindex="0" role="button" aria-labelledby="button-label" class="nsm7Bb-HzV7m-LgbsSe  hJDwNd-SxQuSe i5vt6e-Ia7Qfc uaxL4e-RbRzK"><div class="nsm7Bb-HzV7m-LgbsSe-MJoBVe"></div><div class="nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb "><div class="nsm7Bb-HzV7m-LgbsSe-Bz112c"> <i className="fa fa-facebook"></i></div><span class="nsm7Bb-HzV7m-LgbsSe-BPrWId">Sign in with Facebook</span><span class="L6cTce" id="button-label">Sign in with Facebook</span></div></div></div>
                   
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="signup-image">
              <figure>
                <img src={signupImage} alt="sign up" />
              </figure>
              <Link to="/login" className="signup-image-link">
                I am already a member
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
