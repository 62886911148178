import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./ImageComparison.css";

const ImageComparison = () => {
  const [sliderPosition, setSliderPosition] = useState(50); // Initial slider position
  const containerRef = useRef(null); // Reference to the image container
  const sliderRef = useRef(null); // Reference to the slider bar
  const beforeLabelRef = useRef(null); // Reference to "Before" label
  const afterLabelRef = useRef(null); // Reference to "After" label

  // Function to handle the animation on scroll
  const triggerAnimation = () => {
    gsap.fromTo(
      sliderRef.current,
      { left: "0%" },
      { left: "50%", duration: 2, ease: "power2.inOut" }
    );

    // Futuristic glow effect for labels
    gsap.fromTo(
      [beforeLabelRef.current, afterLabelRef.current],
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, duration: 1.5, delay: 0.5, ease: "elastic.out(1, 0.5)" }
    );
  };

  // Intersection Observer for triggering the animation when the section is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Check if the container is in view
        if (entries[0].isIntersecting) {
          triggerAnimation();
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the section is in view
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleMouseMove = (e) => {
    const containerWidth = containerRef.current.offsetWidth;
    const newPos = ((e.clientX - containerRef.current.getBoundingClientRect().left) / containerWidth) * 100;
    setSliderPosition(Math.min(Math.max(newPos, 0), 100));
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div className="image-comparison-container" ref={containerRef}>
  <h2>Before vs. After: <span style={{ color: '#575052' }}>What Do You See?</span></h2>
      <p>Custom solutions for web owners, developers, and designers, optimizing website performance for every project. Unlock the benefits of quicker load times with our advanced image optimization tools.</p>
      <div className="image-comparison">
        {/* Before Image */}
        <img
          src="/imageConverter/before.webp" // Update this path
          alt="Before Compression"
          className="comparison-image before-image"
          style={{ clipPath: `polygon(0 0, ${sliderPosition}% 0, ${sliderPosition}% 100%, 0 100%)` }}
        />
        
        {/* After Image with clip-path */}
        <img
          src="/imageConverter/compressed-image.webp" // Update this path
          alt="After Compression"
          className="comparison-image after-image"
        />
        {/* Vertical Slider Bar */}
        <div
          ref={sliderRef}
          className="slider-bar"
          style={{ left: `${sliderPosition}%` }}
          onMouseDown={handleMouseDown}
        ></div>
        {/* Labels */}
        <div
          ref={beforeLabelRef}
          className="before-label"
          style={{ right: `calc(100% - ${sliderPosition}%)` }}
        >
          Before
        </div>
        <div
          ref={afterLabelRef}
          className="after-label"
          style={{ left: `${sliderPosition}%` }}
        >
          After
        </div>
      </div>
    </div>
  );
};

export default ImageComparison;
